import React, { useState, useEffect, useRef } from 'react'
import dropdownStyles from './dropdown.module.scss'
import ArrowDown from './arrowBottom.svg'
import { navigate } from 'gatsby'

export default function Dropdown({
  collapsed,
  styles,
  categories = [],
  currentHash,
  onClick,
}) {
  const hashName = currentHash.category ? currentHash.category : ''
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const [name, setName] = useState(hashName)
  const wrapperRef = useRef(null)

  useEffect(() => {
    // calc element size for CSS variable
    const wrapper = wrapperRef.current
    wrapper.style.setProperty('--dropdownWidth', `${wrapper.offsetWidth}px`)
    //console.log('categories', categories)
    console.log('hashName', hashName)
    console.log('currentHash', currentHash)

    if (currentHash.category) setName(hashName)

    const list = document.querySelector(`.${dropdownStyles.list}`)

    for (let sibling of list.children) {
      const child = sibling.firstChild
      child.classList.remove(dropdownStyles.active)

      if (child.firstChild.innerText.toLowerCase() === hashName) {
        child.classList.add(dropdownStyles.active)
      }
    }
  }, [currentHash.category, currentHash, hashName])

  const colapseToggle = () => {
    setIsCollapsed(!isCollapsed)
    setName(isCollapsed ? null : hashName)
  }

  const activeToggle = e => {
    const that = e.currentTarget.firstChild
    colapseToggle()
    setName(that.firstChild.innerText)
    onClick && onClick(that)

    that.classList.add(dropdownStyles.active)

    for (let sibling of that.parentNode.children) {
      if (sibling !== that) sibling.classList.remove(dropdownStyles.active)
    }

    /*
     * Go to gallery
     **/
    console.log('currentHash', currentHash)
    const section = window.location.pathname.split('/')
    let url = `/${section[1]}?category=${that.innerText.toLowerCase()}`
    navigate(url, { replace: true })
  }

  const cageoriesFiltered = categories.filter(category => {
    const virtualExhibition = category.relationships.node__artwork?.filter(
      node => node.field_virtual_exhbition === true
    )
    return (
      category.name.toLowerCase() !== 'sculptures' &&
      category.relationships.node__artwork?.length > 0 &&
      virtualExhibition?.length > 0
    )
  })

  return (
    <div
      className={`${dropdownStyles.container} 
        ${styles} 
        ${isCollapsed ? dropdownStyles.collapsed : ''}
      `}
      ref={wrapperRef}
    >
      <header
        tabIndex={0}
        role="button"
        className={dropdownStyles.header}
        onClick={colapseToggle}
        onKeyDown={colapseToggle}
      >
        {isCollapsed && <ArrowDown className={dropdownStyles.arrowDown} />}
        <h1>{!name ? 'Exhibitions' : name.toLowerCase()}</h1>
      </header>
      <ul className={dropdownStyles.list}>
        {cageoriesFiltered.map(element => (
          <li key={element.id}>
            <button
              onClick={e => {
                activeToggle(e)
              }}
              onKeyDown={e => activeToggle(e)}
            >
              <span>{element.name.toLowerCase()}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
