// From https://davidwalsh.name/javascript-debounce-function.
export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

// from http://www.quirksmode.org/js/events_properties.html#position
export function getMousePos(e) {
  var posx = 0
  var posy = 0
  if (!e) e = window.event
  if (e.pageX || e.pageY) {
    posx = e.pageX
    posy = e.pageY
  } else if (e.clientX || e.clientY) {
    posx =
      e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
    posy =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop
  }
  //console.log('posMouse', posx, posy)
  return {
    x: posx,
    y: posy,
  }
}

export const verifyHashPosition = name => {
  let val
  switch (name) {
    case '#collages':
      val = 0
      break
    case '#drawings':
      val = 1
      break
    case '#paintings':
      val = 2
      break
    case '#prints':
      val = 3
      break
    case '#sculptures':
      val = 4
      break
    case '#textiles':
      val = 5
      break
    case '#watercolors':
      val = 6
      break
    default:
      val = 0
      break
  }
  return { val, hash: name, name: name.substring(1) }
}
export const verifyNumberPosition = num => {
  let hash
  switch (num) {
    case 0:
      hash = '#collages'
      break
    case 1:
      hash = '#drawings'
      break
    case 2:
      hash = '#paintings'
      break
    case 3:
      hash = '#prints'
      break
    case 4:
      hash = '#sculptures'
      break
    case 5:
      hash = '#textiles'
      break
    case 6:
      hash = '#watercolors'
      break
    default:
      hash = '#collages'
      break
  }
  return { num, hash, name: hash.substring(1) }
}

export const initialData = {
  currentRoom: 0,
  hash: {
    category: '',
    page: 0,
  },
  rooms: {},
  onEndTransition: function (el, callback) {
    console.log('onEndTransition', el)
    const onEndCallbackFn = function (ev) {
      this.removeEventListener('transitionend', onEndCallbackFn)
      if (callback && typeof callback === 'function') {
        callback.call()
      }
    }
    el.addEventListener('transitionend', onEndCallbackFn)
  },
  scroller: null,
  // Total number of rooms.
  totalRooms: 0,
  scrollerStyle: { transform: '' },
  transitionStyle: { transition: '' },
  initTransform: {
    translateX: 0,
    translateY: 0,
    translateZ: '500px',
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
  },
  // Reset transform.
  resetTransform: {
    translateX: 0,
    translateY: 0,
    translateZ: 0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
  },
  // View from top.
  //menuTransform: {
  //translateX: 0,
  //translateY: '150%',
  //translateZ: 0,
  //rotateX: '15deg',
  //rotateY: 0,
  //rotateZ: 0,
  //},
  menuTransform: {
    translateX: 0,
    translateY: '50%',
    translateZ: 0,
    rotateX: '-10deg',
    rotateY: 0,
    rotateZ: 0,
  },
  // Info view transform.
  infoTransform: {
    translateX: 0,
    translateY: 0,
    translateZ: '200px',
    rotateX: '2deg',
    rotateY: 0,
    rotateZ: '4deg',
  },
  // Room initial moving transition.
  initTransition: { speed: '0.9s', easing: 'ease' },
  // Room moving transition.
  roomTransition: { speed: '0.4s', easing: 'ease' },
  // View from top transition.
  menuTransition: { speed: '1.5s', easing: 'cubic-bezier(0.2,1,0.3,1)' },
  // Info transition.
  infoTransition: { speed: '15s', easing: 'cubic-bezier(0.3,1,0.3,1)' },
  // Tilt transition
  tiltTransition: { speed: '0.2s', easing: 'ease-out' },
  tilt: false,
  // How much to rotate when the mouse moves.
  tiltRotation: {
    rotateX: 1, // a relative rotation of -1deg to 1deg on the x-axis
    rotateY: -3, // a relative rotation of -3deg to 3deg on the y-axis
  },
  // Window sizes.
  win: { width: 0, height: 0 },
  // Check if moving inside the room and check if navigating.
  isMoving: false,
  isNavigating: false,
}
