import React, { Component } from 'react'
import galleryStyles from './gallery.module.scss'
import { debounce, getMousePos, initialData } from './gallery.helper.js'
import Room from '../room/room.component'
import { navigate as navi } from 'gatsby'

class Gallery extends Component {
  state = {
    ...initialData,
    arrowsDirection: { name: null, count: 0 },
    roomList: {
      roomListData: [],
      total: 0,
    },
  }

  scrollerRef = React.createRef()

  async componentDidMount() {
    this.setState({ roomList: await this.roomsFilter() }, () => {
      console.log('roomList', this.state.roomList)
      this.startGallery()
    })

    window.addEventListener('keydown', this.onKeyDown)
  }

  roomsFilter = async () => {
    const { data, categories } = this.props
    let rooms = []
    let sum = 0
    let page = 0

    /*
     * Filter nodeArtworks only if has a value field_virtual_exhbition: {eq: true}
     **/
    await categories.forEach(category => {
      const categ = data.filter(element => {
        return (
          element.node.relationships.imgMobile[0].localFile &&
          element.node.relationships.field_category.name === category.name
        )
      })

      /*
       * Adding romms only if categ hav elements
       **/

      if (categ.length > 0) {
        /*
         * Category is distributed parts of 8 elements
         * Every room have
         * page (Number)
         * category (String)
         * room (Array)
         **/

        sum += categ.length
        const _rooms = this.chunk(categ, 8)
        //console.log('rooms', _rooms)
        _rooms.forEach(room => {
          page += 1
          rooms.push({
            key: category.id + '-----' + page,
            page,
            category: category.name,
            totalRoom: categ.length,
            room,
            rooms: room.length,
          })
          //}
        })
      }
    })

    return {
      roomListData: rooms,
      total: sum,
    }
  }

  /*
   * Check if the room exist
   **/
  whereIm = () => {
    const { currentHash } = this.props
    const { roomList } = this.state

    const where = roomList.roomListData.find(rooms => {
      return rooms.category.toLowerCase() === currentHash.category
    })

    return {
      where: where ? where : roomList.roomListData[0],
      page: currentHash.page,
      category: currentHash.category,
    }
  }

  /*
   * Start all animation off this gallery
   */
  startGallery = () => {
    const { initTransition, initTransform } = this.state
    this.move({
      transition: initTransition,
      transform: initTransform,
    }).then(() => {
      console.log('iniciando eventos', this.state)
      this.initTilt()
    })

    /*
     * Rooms count
     */
    const rooms = [].slice.call(
      document.querySelectorAll(`.${galleryStyles.room}`)
    )

    const whereIm = this.whereIm()
    console.log('------- where im -------', whereIm)

    let currPos = whereIm.page
      ? whereIm.page < rooms.length + 1
        ? whereIm.page - 1
        : whereIm.where.page - 1
      : whereIm.category
      ? whereIm.where.page - 1
      : 0
    console.log('currPos', currPos)

    this.setState(
      {
        totalRooms: rooms.length,
        rooms,
        win: { width: window.innerWidth, height: window.innerHeight },
        isMoving: false,
        isNavigating: false,
        hash: this.whereIm(),
        currentRoom: Number(currPos),
      },
      () => {
        for (let sibling of rooms) {
          sibling.classList.remove(galleryStyles.roomCurrent)
          sibling.style.opacity = 0
        }
        const roomPos = currPos ? currPos : 0
        if (rooms[roomPos]) {
          rooms[roomPos].classList.add(galleryStyles.roomCurrent)
          rooms[roomPos].style.opacity = 1
        }
      }
    )
    document.addEventListener('mousemove', this.onMouseMoveFn)
    window.addEventListener('resize', this.debounceResizeFn)
  }

  //remove events
  componentWillUnmount() {
    this.removeTilt()
    document.removeEventListener('mousemove', this.onMouseMoveFn)
    window.removeEventListener('resize', this.debounceResizeFn)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('click', this.onHandleClick)
    console.log('componentWillUnmount', this.state)
  }

  static getDerivedStateFromProps(props, state) {
    // obtain direction data from props and only update if props changed
    if (props.onHandleDirection.count !== state.arrowsDirection.count) {
      return {
        arrowsDirection: props.onHandleDirection,
      }
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    /*
     * Update only if currentHash change from props
     */
    const { isNavigating } = this.state
    const { currentHash, onHandleDirection } = this.props

    /**
     * handling click of arrows
     */
    if (onHandleDirection.count !== prevProps.onHandleDirection.count) {
      !isNavigating && this.navigate(onHandleDirection.name)
    }

    /**
     * handling hash from url
     */
    if (prevProps.currentHash.category !== prevState.hash.category) {
      this.setState({ hash: currentHash }, () => {
        if (!isNavigating) this.startGallery()
      })
    }
  }

  initTilt = () => {
    const { tiltTransition } = this.state

    this.setState({ tilt: true }, () => {
      this.applyRoomTransition(tiltTransition)
    })
  }

  removeTilt = () => {
    this.setState({ tilt: false })
  }

  move = opts => {
    return new Promise((resolve, reject) => {
      console.log('promesa', this.state.isMoving && opts.stopTransition)
      if (this.state.isMoving && !opts.stopTransition) {
        return reject()
      }

      let isMoving = true
      this.setState({ isMoving }, () => {
        if (opts.transition) {
          this.applyRoomTransition(opts.transition)
          console.log('applyRoomTransition', 'isMoving', this.state.isMoving)
        }

        if (opts.transform) {
          this.applyRoomTransform(opts.transform)
          console.log('applyRoomTransform')

          const onEndFn = () => {
            isMoving = false
            this.setState({ isMoving }, () => {
              console.log('resolve1', 'isMoving', this.state.isMoving)
              resolve()
            })
          }

          this.state.onEndTransition(this.scrollerRef.current, onEndFn)
        } else {
          console.log('resolve2')
          resolve()
        }
      })
    })
  }

  applyRoomTransform = optsTransition => {
    const scrollerStyle = {
      transform:
        'translate3d(' +
        optsTransition.translateX +
        ', ' +
        optsTransition.translateY +
        ', ' +
        optsTransition.translateZ +
        ') ' +
        'rotate3d(1,0,0,' +
        optsTransition.rotateX +
        ') rotate3d(0,1,0,' +
        optsTransition.rotateY +
        ') rotate3d(0,0,1,' +
        optsTransition.rotateZ +
        ')',
    }
    this.setState({ scrollerStyle })
  }

  applyRoomTransition = opsTransition => {
    const transition =
      opsTransition === 'none'
        ? { transition: opsTransition }
        : {
            transition:
              'transform ' + opsTransition.speed + ' ' + opsTransition.easing,
          }
    this.setState({ transitionStyle: transition })
  }
  // Mousemove event / Tilt functionality.
  onMouseMoveFn = ev => {
    const { tilt, tiltRotation, win, initTransform } = this.state
    if (!tilt) return false

    const mousepos = getMousePos(ev)
    // transform values
    const rotX = tiltRotation.rotateX
      ? initTransform.rotateX -
        (((2 * tiltRotation.rotateX) / win.height) * mousepos.y -
          tiltRotation.rotateX)
      : 0
    //
    const rotY = tiltRotation.rotateY
      ? initTransform.rotateY -
        (((2 * tiltRotation.rotateY) / win.width) * mousepos.x -
          tiltRotation.rotateY)
      : 0

    // apply transform
    this.applyRoomTransform({
      translateX: initTransform.translateX,
      translateY: initTransform.translateY,
      translateZ: initTransform.translateZ,
      rotateX: rotX + 'deg',
      rotateY: rotY + 'deg',
      rotateZ: initTransform.rotateZ,
    })
  }
  // Window resize.
  debounceResizeFn = debounce(() => {
    const win = { width: window.innerWidth, height: window.innerHeight }
    this.setState({ win })
  }, 10)

  navigate = dir => {
    const {
      isMoving,
      initTransform,
      isNavigating,
      roomTransition,
      resetTransform,
      currentRoom,
      totalRooms,
      rooms,
      hash,
      roomList,
    } = this.state

    console.log('navigate click dir', dir)
    console.log('navigate click hash', hash)
    const room = rooms[currentRoom]
    let current = Number(currentRoom)

    if (isMoving && isNavigating) {
      return false
    }

    // Remove tilt.
    this.removeTilt()

    // Update currentRoom.
    if (dir === 'next') {
      current = currentRoom < totalRooms - 1 ? currentRoom + 1 : 0
    } else {
      current = currentRoom > 0 ? currentRoom - 1 : totalRooms - 1
    }

    /*
     * Handle change for parent component position
     */
    const page = current + 1
    const pos = roomList.roomListData.find(ele => {
      return page === ele.page
    })
    console.log('pos', pos, 'current', current)
    const category = pos ? pos.category.toLowerCase() : 'collage'

    this.props.onHandleChange(category.toLowerCase(), page)
    const section = window.location.pathname.split('/')
    navi(`/${section[1]}?category=${category.toLowerCase()}&page=${page}`)

    //// Position the next room.
    const nextRoom = rooms[current]
    nextRoom.style.transform =
      'translate3d(' +
      (dir === 'next' ? 100 : -100) +
      '%,0,0) translate3d(' +
      (dir === 'next' ? 1 : -1) +
      'px,0,0)'
    nextRoom.style.opacity = 1

    this.setState({ isNavigating: true, currentRoom: current }, () => {
      // Move back.
      this.move({ transition: roomTransition, transform: resetTransform })
        .then(() => {
          console.log('step 1')
          // Move left or right.
          return this.move({
            transform: {
              translateX: (dir === 'next' ? -100 : 100) + '%',
              translateY: 0,
              translateZ: 0,
              rotateX: 0,
              rotateY: 0,
              rotateZ: 0,
            },
          })
        })
        .then(() => {
          console.log('step 2')
          // Update current room class.
          nextRoom.classList.add(galleryStyles.roomCurrent)
          room.classList.remove(galleryStyles.roomCurrent)
          room.style.opacity = 0

          // Move into room.
          // Update final transform state:
          return this.move({
            transform: {
              translateX: (dir === 'next' ? -100 : 100) + '%',
              translateY: 0,
              translateZ: '500px',
              rotateX: 0,
              rotateY: 0,
              rotateZ: 0,
            },
          })
        })
        .then(() => {
          console.log('step 3')
          // Reset positions.
          this.applyRoomTransition('none')
          nextRoom.style.transform = 'translate3d(0,0,0)'
          this.applyRoomTransform(initTransform)
          this.setState({ isNavigating: false })

          setTimeout(() => {
            this.initTilt()
          }, 80)
        })
    })
  }
  /*
   * Key down event
   */
  onKeyDown = e => {
    console.log('onKeyDown')
    e.preventDefault()
    if (e.which === 37) this.navigate('prev')
    if (e.which === 39) this.navigate('next')
  }
  /*
   * Slice array in groups
   * arr (Array)
   * size (Number)
   **/
  chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
    )

  render() {
    const { transitionStyle, scrollerStyle, roomList } = this.state

    this.props.isSearchOpen &&
      window.removeEventListener('keydown', this.onKeyDown)

    return (
      <div
        className={`${galleryStyles.container} ${galleryStyles.load}`}
        onKeyDown={this.props.isSearchOpen ? null : this.onKeyDown}
        tabIndex="-2"
        role="button"
      >
        {/* content */}
        <div className={galleryStyles.content}>
          {/*children content*/}
          {this.props.children}
        </div>
        {/* scroller */}
        <div
          className={galleryStyles.scroller}
          ref={this.scrollerRef}
          style={{
            ...transitionStyle,
            ...scrollerStyle,
          }}
        >
          {roomList.roomListData.map(element => {
            return (
              <Room
                key={element.key}
                className={galleryStyles.room}
                data={element.room}
                page={element.page}
                category={element.category}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default Gallery
