import React, { useState, useEffect, useContext } from 'react'
import roomStyles from './room.module.scss'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import ThemeContext from '../../context/ThemeContext'
import { useSize } from '../../hooks/size.hook'
import BackgroundImage from 'gatsby-background-image'
import Image from '../../components/image/image.component'

export default function Room({ className, category, page, data = [] }) {
   const [current, setCurrent] = useState({
      sideBack: [],
      sideLeft: [],
      sideRight: [],
      data: [],
   })
   const [currentHover, setCurrentHover] = useState(null)
   const { isMobile } = useSize()

   const chunk = (arr, size) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
         arr.slice(i * size, i * size + size)
      )

   const { bgWall } = useStaticQuery(graphql`
      query {
         bgWall: file(
            relativeDirectory: { eq: "backgrounds" }
            base: { eq: "f1.jpg" }
         ) {
            childImageSharp {
               fluid(quality: 60, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
      }
   `)

   useEffect(() => {
      const _data = chunk(data, 3)

      //console.log('----------- data', data)
      const sideBack = _data[2] ? _data[2] : []
      const sideLeft = _data[0] ? _data[0] : []
      const sideRight = _data[1] ? _data[1] : []

      setCurrent({
         sideBack,
         sideLeft,
         sideRight,
         data: [...sideLeft, ...sideBack, ...sideRight],
      })
   }, [data])

   const context = useContext(ThemeContext)

   const go = element => {
      context.setGoBack(() => {
         return navigate(
            `/virtual-exhibitions?category=${category.toLowerCase()}&${page}`
         )
      })

      context.setIsStudio(false)
      element.field_object_number &&
         navigate(`/artwork/${element.field_object_number}`)
   }

   const onHover = name => {
      console.log('onMouseUp', name)
      setCurrentHover(name)
   }

   return (
      <div className={`${roomStyles.room} ${className}`}>
         {/* buttons over the images */}
         <div className={roomStyles.columns}>
            {current.data.length >= 8 &&
               current.data.map((element, key) => {
                  //console.log('---- element', element)
                  //element.forEach(el => console.log('----------', el))
                  if (key < 8) {
                     const myImage = isMobile
                        ? element.node.relationships.imgMobile[0].localFile
                             .childImageSharp.fixed
                        : element.node.relationships.imgDescktop[0].localFile
                             .childImageSharp.fixed
                     return (
                        <button
                           key={key}
                           alt={element.node.name}
                           title={element.node.name}
                           onMouseOver={() => onHover(myImage.src)}
                           onKeyDown={() => go(element.node)}
                           onClick={() => go(element.node)}
                        >
                           {element.node.name}
                        </button>
                     )
                  }
                  return null
               })}
         </div>
         {/* side back */}
         <div className={`${roomStyles.roomSide} ${roomStyles.roomSideBack}`}>
            <BackgroundImage
               Tag='section'
               fluid={bgWall.childImageSharp.fluid}
               style={{
                  position: 'absolute',
                  width: '100vw',
                  height: '100vh',
                  height: 'calc(var(--vh, 1vh) * 100)',
               }}
               fadeIn
            />

            {current.sideBack.map((image, key) => {
               //console.log('--------image', image)
               if (key < 2 && image.node.field_image) {
                  const myImage = isMobile
                     ? image.node.relationships.imgMobile[0].localFile
                          .childImageSharp.fixed
                     : image.node.relationships.imgDescktop[0].localFile
                          .childImageSharp.fixed
                  return (
                     <Image
                        key={key}
                        className={`${roomStyles.roomImg} ${
                           currentHover === myImage.src
                              ? roomStyles.roomHover
                              : ''
                        }`}
                        src={myImage}
                        {...image.node.field_image}
                        title={image.node.field_image.title}
                        background={null}
                        width={isMobile ? 200 : 500}
                     />
                  )
               }
               return null
            })}
         </div>
         {/* side left */}
         <div
            className={`${roomStyles.roomSide} ${roomStyles.roomSideLeft}`}
            style={{ backgroundColor: '#fcf6f3' }}
         >
            {current.sideLeft.map((image, key) => {
               if (key < 3 && image.node.field_image) {
                  const myImage = isMobile
                     ? image.node.relationships.imgMobile[0].localFile
                          .childImageSharp.fixed
                     : image.node.relationships.imgDescktop[0].localFile
                          .childImageSharp.fixed
                  return (
                     <Image
                        key={key}
                        className={`${roomStyles.roomImg} ${
                           currentHover === myImage.src
                              ? roomStyles.roomHover
                              : ''
                        }`}
                        src={myImage}
                        {...image.node.field_image}
                        title={image.node.field_image.title}
                        background={null}
                        width={isMobile ? 200 : 500}
                     />
                  )
               }
               return null
            })}
         </div>

         {/* side right */}
         <div
            className={`${roomStyles.roomSide} ${roomStyles.roomSideRight}`}
            style={{ backgroundColor: '#fcf6f3' }}
         >
            {current.sideRight.map((image, key) => {
               if (key < 3 && image.node.field_image) {
                  const myImage = isMobile
                     ? image.node.relationships.imgMobile[0].localFile
                          .childImageSharp.fixed
                     : image.node.relationships.imgDescktop[0].localFile
                          .childImageSharp.fixed
                  return (
                     <Image
                        key={key}
                        className={`${roomStyles.roomImg} ${
                           currentHover === myImage.src
                              ? roomStyles.roomHover
                              : ''
                        }`}
                        src={myImage}
                        {...image.node.field_image}
                        title={image.node.field_image.title}
                        background={null}
                        width={isMobile ? 200 : 500}
                     />
                  )
               }
               return null
            })}
         </div>

         {/* side bottom */}
         <div
            className={`${roomStyles.roomSide} ${roomStyles.roomSideBottom}`}
            style={{ backgroundColor: '#F9ece5' }}
         ></div>
      </div>
   )
}
