import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../components/layout/layout.component'
import Gallery from '../../components/gallery/gallery.component'
import Search from '../../components/search/search.component'
import stylesGallery from './gallery.page.module.scss'
import Dropdown from '../../components/dropdown/dropdown.component'
import { graphql, useStaticQuery } from 'gatsby'
import ThemeContext from '../../context/ThemeContext'
import ArrowsLeftRight from '../../components/arrowsLR/arrows.left.right.component'

export default function GalleryPage() {
  const { allNodeArtwork, allTaxonomyTermCategory } = useStaticQuery(graphql`
    query {
      allTaxonomyTermCategory(sort: { fields: name }) {
        nodes {
          id
          name
          relationships {
            node__artwork {
              field_virtual_exhbition
            }
          }
        }
      }
      allNodeArtwork(filter: { field_virtual_exhbition: { eq: true } }) {
        edges {
          node {
            id
            field_medium
            title
            field_date
            field_object_number
            field_virtual_exhbition
            field_primary_dimensions
            field_studio_wall
            field_has_background
            field_media_quantity
            field_image {
              title
            }

            relationships {
              imgDescktop: field_image {
                localFile {
                  childImageSharp {
                    fixed(width: 500, quality: 60) {
                      src
                      base64
                    }
                  }
                }
              }
              imgMobile: field_image {
                localFile {
                  childImageSharp {
                    fixed(height: 300, quality: 60) {
                      src
                      base64
                    }
                  }
                }
              }

              field_category {
                name
              }
            }
          }
        }
      }
    }
  `)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [currentHash, setCurrentHash] = useState({ category: '', page: 0 })
  const [direction, setDirection] = useState({ name: null, count: 0 })
  const context = useContext(ThemeContext)

  useEffect(() => {
    /*
     * Get currentHash from current url
     **/
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const category = urlParams.get('category')
      ? urlParams.get('category').toLowerCase()
      : 'collage'

    const page = !urlParams.get('page') ? null : urlParams.get('page')
    //console.log('page', page, 'category', category)

    setCurrentHash({
      category,
      page,
    })
  }, [])

  /*
   * Toggle navigation buttons
   **/
  const onToggle = e => {
    setIsSearchOpen(e)
  }

  /*
   * onHandleDropdown handle dropdown menu
   **/
  const onHandleDropdown = (data, page) => {
    let category = data.innerHTML ? data.innerHTML.toLowerCase() : data
    setCurrentHash({ category, page })
  }

  /*
   * navigate handle arrows direction
   **/
  const navigate = name => {
    setDirection({ name, count: direction.count + 1 })
  }

  console.log('allNodeArtwork gallery', allNodeArtwork.edges)
  return (
    <>
      <Layout
        showFooter
        to="/studio/workshop"
        className={stylesGallery.container}
        noBackgorund
      >
        <Gallery
          isSearchOpen={isSearchOpen}
          currentHash={currentHash}
          onHandleChange={onHandleDropdown}
          data={allNodeArtwork.edges}
          categories={allTaxonomyTermCategory.nodes}
          onHandleDirection={direction}
        >
          <Dropdown
            collapsed
            categories={allTaxonomyTermCategory.nodes}
            styles={stylesGallery.collections}
            currentHash={currentHash}
            onClick={onHandleDropdown}
          />
        </Gallery>
      </Layout>

      <div className={`${stylesGallery.bottomSection}`}>
        {!context.open && (
          <Search
            hasBackground={!context.open ? false : true}
            onToggle={onToggle}
            data={allNodeArtwork.edges}
          />
        )}

        {/* arrows */}
        {!context.open && !isSearchOpen && allNodeArtwork.edges.length > 1 && (
          <ArrowsLeftRight id="navigation-arrows" onClick={navigate} />
        )}
      </div>
    </>
  )
}
